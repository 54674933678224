import React, { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Popup,
  Table,
  Button, 
  Icon
} from "semantic-ui-react";
import cx from "classnames";
import dots from "../../styles/images/three-dots-vertical.svg";
import Paginations from "../Pagination/Paginations";
import TableTab from "../Card/TableTab";
import { isEmpty } from "lodash";
import { DateInput } from "semantic-ui-calendar-react";
import { store } from "../../redux/store";
import { useHistory } from "react-router-dom";
import { capital } from "../../utils/lodash";
import { TotalModulesList, TOTAL_ID, JOBS } from "../../utils/labelContant";
import { accessActionType } from "../../utils/accessConstant";
import { CSVLink } from "react-csv";
import { getCusLocalDate } from "../../utils/localTime";
import moment from "moment";

export default function CustomTable({
  tabs = [],
  disableEmptyTableUi = false,
  smallInfo = false,
  activeTab,
  onTabClick,
  currentTabName,
  activePage,
  totalData,
  cols = [],
  data = [],
  count = 0,
  pageLimit = 10,
  setPagination,
  loading = false,
  error = null,
  renderMenuItem,
  onClickEv,
  clickable,
  className,
  approval,
  exportData,
  ...props
}) {
  // for pagination get page count
  const [pageCount, setCount] = useState(0);
  const history = useHistory();
  useEffect(() => {
    !approval? setCount(Math.ceil(count / pageLimit)): setCount(Math.ceil(count / 25));
    return () => { };
  }, [count]);

  let path = history.location.pathname.split(/[/-]+/);
  let moduleName = "";
  if (path && path[1]) {
    moduleName = capital(path[1]);
  }
  // table header with search
  const renderSearchDropDown = useCallback(
    (placeholder, options = [], text, type, fn, search, index, date) => {
      if (isEmpty(options)) {
        // search with text
        if (search) {
          return (
            <Input
              value={text}
              icon="search"
              placeholder={placeholder}
              className={cx("headerInput w-100", {
                headerSearchClient: index === 0,
              })}
              onChange={(e) => fn(e.target.value, type)}
            />
          );
        }
        // table header search with date
        else if (date === true) {
          return (
            <DateInput
              className={"ApprovalCalendar whole-span cusWidthApproval"}
              popupPosition="bottom center"
              icon={"calendar outline large w-100"}
              closeOnMouseLeave
              placeholder={placeholder}
              clearable
              search
              value={text}
              width={7}
              iconPosition="right"
              dateFormat={"DD-MM-YYYY"}
              hideMobileKeyboard
              animation={"drop"}
              onChange={(event, { name, value }) => {
                event.target.value = value;
                event.target.name = "dob";
                fn(event.target.value, type);
              }}
            />
          );
        } else {
          return placeholder;
        }
      }
      // search with dropdown
      return (
        <Dropdown
          className="ApprovalDropdown w-100"
          placeholder={placeholder}
          options={options}
          value={text}
          search
          selection
          clearable
          selectOnBlur={false}
          onChange={(e, { value }) => {
            fn(value, type);
          }}
        />
      );
    },
    []
  );
  // const renderOnClick = useCallback((clickEv) => {
  //   return(<></>)
  // }, [])

  // custom Table headers
  const renderHeader = () => {
    return cols.map((item, index) => {
      // table Header with checkbox
      return item.checkbox ? (
        <Table.HeaderCell className="fixed-row table-with-drop-down table-header">
          <Checkbox
            className="checkboxTablehead"
            onChange={item.handleCheckboxAction}
            checked={item.allChecked}
          ></Checkbox>
        </Table.HeaderCell>
      ) : (
        <Table.HeaderCell
          className={cx(`table-with-drop-down table-header ${item.id}`, {
            "fixed-row": index === 0 || (item.fixed && item.fixed),
          } , {"fixed-row-custom-table": item.id === 'total'})}
          key={`table_header_title__${index}`}
        >
          {renderSearchDropDown(
            item.title,
            item.options,
            item.text,
            item.type,
            item.onSelect,
            item.search,
            item.checked,
            item.date,
            index
          )}
        </Table.HeaderCell>
      );
    });
  };
  const trigger = () => {
    return <img src={dots} className="dotImage pointer" alt="Dispatch" />;
  };

  // table body
  const renderBody = () => {
    return data?.map((row, rowIndex) => {
      let ModuleAccess = true;
      if (
        TotalModulesList.filter((value) =>
          value?.moduleName.includes(moduleName)
        )?.length > 0 &&
        moduleName?.length
      ) {
        let isAccess = store
        ?.getState()
        .global?.access.filter(
          (e) =>
            e.access_details?.module?.name.includes(moduleName) &&
              (e.access_details?.module?.name !== JOBS ? 
              e.access_details?.status !== accessActionType.READ
           : e.access_details?.status === accessActionType.READ)
        );
        ModuleAccess = isAccess?.length >= 1;
      }

      return (
        <Table.Row key={`row_${rowIndex}`}>
          {cols.map((col, colIndex) => {
            return col.formatter ? (
              <Table.Cell
                className={cx(`table-body ${col.id}`, {
                  "fixed-row": (colIndex === 0  ||  col.id === 'total') || (col.fixed && col.fixed),
                }, {"fixed-row-custom-table": col.id === 'total'})}
                key={`col_${rowIndex}_${colIndex}`}
              // onClick={() => testFn(row)}
              >
                {col.formatter(row, rowIndex, col.id)}
              </Table.Cell>
            ) : (
              <Table.Cell
                className={cx(`table-body ${col.id}`, {
                  "fixed-row": colIndex === 0 || (col.fixed && col.fixed),
                }, {"fixed-row-custom-table": col.id === 'total'})}
                key={`col_${rowIndex}_${colIndex}`}
              >
                {colIndex === 0 && renderMenuItem && ModuleAccess && (
                  <Popup
                    on="click"
                    onClose={() => { }}
                    onOpen={() => { }}
                    trigger={trigger()}
                    wide
                    position="bottom left"
                    className="table-option-popup  left pointer"
                    key={"menu_dropdown" + rowIndex + colIndex}
                  >
                    {renderMenuItem(row)}
                  </Popup>
                )}
                {/* {row[`${col.id}`]} */}
                {/* onClick on cell open function  */}
                {clickable ? (
                  <div
                    className={row[`${col?.id}`] > 0 && col?.id != TOTAL_ID && row["role"] ? "pointer text_link" : ""}
                    onClick={() =>
                      onClickEv(
                        col.id,
                        row[`${col.id}`],
                        row?.role ? row.role : "",
                        row?.staff_id ? row.staff_id : ""
                      )
                    }
                  >
                    {col?.id == TOTAL_ID ? 
                        <CSVLink data={exportData} filename={`TimeKeeping_${getCusLocalDate(moment())}.csv`}>
                          <Button icon basic color="blue">
                          {row[`${col.id}`]}</Button></CSVLink>
                        : row[`${col.id}`]}
                  </div>
                ) : (
                  // <h1>Hello</h1>
                  <div>
                    {
                      col.id === "isActive" && typeof row[col.id] === "boolean"
                        ? row[col.id] ? "Active" : "Inactive" // Map boolean values to "Active"/"Inactive"
                        : row[col.id] // Render other values directly
                    }
                  </div>


                )}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });
  };
  const renderTabs = () => {
    return tabs.map((tab, tabIndex) => {
      return (
        <Grid.Column
          mobile={8}
          tablet={8}
          computer={2}
          key={`tab_name${tab.title}_${tabIndex}`}
        >
          <TableTab
            loading={props.tabLoading}
            title={tab.title}
            onClickfn={onTabClick}
            image={tab.image}
            activeImage={tab.active_image}
            value={tab.value}
            activeId={activeTab}
            tabId={tab.tabId}
            cKey={`tab_Tab${tab.title}_${tabIndex}`}
          />
        </Grid.Column>
      );
    });
  };
  return (
    <div className="mainDiv ">
      {tabs.length > 0 && <Grid key="tab_grid">{renderTabs()}</Grid>}
      <div
        className={"custom-table " + className}
        style={{
          overflowY: "auto",
          width: "100%",
          maxWidth: "100%",
          marginTop: 10,
        }}
      >
        <Table unstackable>
          <Table.Header key={"table_header"}>
            <Table.Row key={"table_row"}>{renderHeader()}</Table.Row>
          </Table.Header>
          <Table.Body className="tableHover">{renderBody()}</Table.Body>
        </Table>
      </div>
      {/* table with no data then throw record not found */}
      {data.length <= 0 &&
        error &&
        loading === false &&
        !disableEmptyTableUi && (
          <div className={`no-record-found ${smallInfo && "sm"}`}>
            No Record Found
          </div>
        )}
      {loading && isEmpty(data) && (
        <div className={`no-record-found ${smallInfo && "sm"}`}>Loading...</div>
      )}
      {/* if count is greater than 10 set pagination*/}
      {count > pageLimit && (
        <div className="customPagination">
          <Paginations
            totalPages={pageCount}
            approval={approval}
            activePage={activePage}
            setActivePage={(page) => setPagination(page)}
          />
        </div>
      )}
    </div>
  );
}